#slider, #apresentacao, #comofunciona, #automacoes, #possibilidades, #tecnologia, #contato {
  scroll-margin: 70px;
}

#slider {
  margin-top: -5px;
  background-color: #FFF5E4;
}

#possibilidades {
  background-color: #FFF5E4;
}

#automacoes {
  background-color: #FFF5E4;
}

#contato, .main-container, body {
  background-color: #4C0033;
}

.footer {
  /* background-color: #EE6983 !important; */
  background-color: #790252 !important;

}


.postcontent h3, label {
  color: #FFF5E4;
}

.purple-gradient {
  background: rgb(232,15,136);
  background: linear-gradient(0deg, rgba(232,15,136,1) 0%, rgba(121,2,82,1) 100%);
}

div.fbox-icon > a > i, div.fbox-icon i {
  color: #4C0033 !important;
}

#header-wrap {
  /* background: #ebf1f0; */
  /* background: #ebf1f0 !important; */
  background-color: #CFD2CF;
  background-color: #E80F88;
  background-color: #4C0033;
}

.menu-link {
  color: whitesmoke;
}

.menu-link:hover {
  /* color: #8b1c0d !important; */
  /* color: #E80F88 !important; */
  color: #FFC4C4 !important;
}

.fancy-title > h3 {
  text-transform: uppercase;
}

.title-bottom-border h3 {
  border-bottom: 2px solid #4C0033;
}

.video-label {
  font-family: 'Lato, sans-serif';
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

/* icone scroll to top  */
.top-to-btm {
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #4C0033;
  border: 0px solid #FFC4C4;
  border-radius: 10%;
  height: 40px;
  width: 40px;
  color: #FFC4C4;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #FFC4C4;
  color: #4C0033;
  border: 0px solid #272727;
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(10px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-10px);
  }
  100%{
    transform: translateY(0px);
  }
}

.button, .button:hover {
  background-color: #790252 !important;
  color: #fff;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 3200px;
  }
}

/* .react-player { */
  /* border-radius: 10px 10px 10px 10px; */
  /* box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75); */
  /* border: 4px solid black; */
/* } */

/* Carousel item */
.jjfwLM {
  display: grid;
  align-items: flex-end;
  margin-bottom: 15px;
  padding-top: 5px;
}

#automacoes div.fbox-content.center h3 {
  text-transform: none;
}

.jjfwLM:nth-child(n+3) {
  border-top: 2px solid #4C0033;
  padding-top: 20px;
}

#apresentacao {
  margin-bottom: 5px;
  background-color: rgb(139, 28, 13);
}

.slider-element {
  background-color: rgb(139, 28, 13);
  background-image: none !important;
}

#myVideo {
  /* position: fixed; */
  top: 60px;
  left: 0;
  margin: auto;
  margin-bottom: -6px;
  width: 100%;
  /* bottom: 0; */
  /* min-width: 90%; */
  /* max-width: 90%; */
  /* min-height: 100%; */
}

.slider-content2 {
  /* position: fixed; */
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

input[type=text], input[type=email], textarea {
  background-color: #FFF5E4 !important;
}

#primary-menu-trigger > svg > path {
  stroke: whitesmoke;
}

.primary-menu-open > #primary-menu-trigger > svg {
  display: block !important;
}
